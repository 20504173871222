.services-container {
    display: flex;
    width: 90%;
    padding: 20px 10px;
    margin: 0px auto;
    margin-top: 2rem;
}
.services-container > * {
    flex: 1;
    margin-bottom: 30px;
}

.services-main-div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    gap: 1rem 1rem;
}

.services-main-div > * {
    flex: 1;
    margin-bottom: 30px;
}

.services-heading {
    font-size: 56px;
    font-weight: 400;
}

.services-subtitle {
    font-size: 20px;
    line-height: normal;
    color: #b5b5b5;
}

/* Media Query */
@media (max-width: 1380px) {
    .services-heading {
        font-size: 40px;
    }
}
@media (max-width: 768px) {
    .services-heading {
        font-size: 30px;
        text-align: center;
    }
    .services-main-div {
        flex-direction: column
    }
}