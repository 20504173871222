.servicecard-main-div {
    border-radius: 20px;
    width: 100%;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-align: center;

}

.servicecard-main-div:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
}

.servicecard-image{
    margin-bottom: 22px;
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    max-height: 128px;
}

.service-default {
    background-color: rgb(253, 253, 247);
    box-shadow: 0px 10px 30px -8px rgb(34, 54, 55);
}
.service-default h3 {
    color: rgb(69, 67, 96);
}
.service-default p.card-subtitle {
    color: rgba(69, 67, 96, 0.9);
}